@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700&family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
#root{padding: 0px;}
:root {
    --leadFont:'Inter', sans-serif;
    --whiteColor:#fff;
    --blackColor:#000;
    --speciBlue:rgb(35,83,139);
  }
  .headFonts{font-family: var(--leadFont);}
  .contFonts{font-family: var(--leadFont);}
  html, body{min-height: 70vh;}
  *{margin: 0;padding: 0;border: 0;text-decoration: none;box-sizing: border-box;list-style: none;}
  a[href^=tel] {text-decoration:inherit;color: inherit;}
  div{padding: 10px;}
  input, select, textarea{font-family: var(--leadFont);}

  h1,h2,h3,h4,h5,h6{font-family: var(--leadFont);}button{background: none;cursor: pointer;}.disInline{display: inline-block;}.disBlock{display: block;}.disFlex{display: flex;}.flexWrap{flex-wrap: wrap;}.disNone{display: none;}.centered{margin-left: auto;margin-right: auto;}.absoVertCenter{left: 50%;transform: translate(-50%, 0);}.absoluteMiddle{left: 50%;top: 50%;transform: translate(-50%, -50%);}.textCenter{text-align: center;}.textLeft{text-align: left;}.textRight{text-align: right;}.noPadd{padding: 0!important;}input:focus, textarea:focus{outline: none;}
  select{width: 100%;background: none;}select:focus{outline: none;}.textUppercase{text-transform: uppercase;}
  .curpoi{cursor: pointer;}textarea{resize: none;}
  .mt05{margin-top: 5px;}.mt10{margin-top: 10px;}.mt15{margin-top: 15px;}.mt20{margin-top: 20px;}
  .mb05{margin-bottom: 5px;}.mb10{margin-bottom: 10px;}.mb15{margin-bottom: 15px;}.mb20{margin-bottom: 20px;}.fw100{font-weight: 100;}.fw300{font-weight: 300;}.fw400{font-weight: 400;}.fw600{font-weight: 600;}.fw700{font-weight: 700;}
  .green{color:#16b306;}.orange{color:#de8410;}.red{color:#e10000;}
  .posAbsolute{position: absolute;}.posRelative{position: relative;}.posFixed{position: fixed;}
  .widthFull{width: 100%;}.widthHalf{width: 50%;}.widthQuarter{width: 25%;}.heightFull{height: 100%;}
  .heightVH{height: 100vh;}.heightHalfVH{height: 50vh;}.carrierSection{width: 100%;max-width: 1400px;padding: 15px;margin-left: auto;margin-right: auto;}
  .vertAlMiddle{vertical-align: middle;}.vertAlLeft{vertical-align: left;}.vertAlRight{vertical-align: right;}.vertAltop{vertical-align: top;}.vertAlbtm{vertical-align: bottom;}
  .z0{z-index: 0;}.z1{z-index: 1;}.z2{z-index: 2;}.z4{z-index: 4;}.z6{z-index: 6;}.z10{z-index: 10;}.coverCenterImage{object-fit: cover;align-items: center;width:100%;}.screenHeight{height: 100vh;}.halfHeight{height: 50vh;}.jcc{justify-content: center;} .jcsb{justify-content: space-between;}.aic{align-items: center;margin: auto;}.clearBoth:after{clear: both;display: block;width: 100%;height: 0px;}p{font-size: 15px;line-height: 26px;opacity: 0.8;}  html{overflow-x: hidden;width: 100%;height: 100%;}body{position: relative;overflow-x: hidden;font-family: var(--leadFont);}

  .hideThis{display: none!important;}

  .loginCarry{height: 100vh;}
  .loginCarry .left{width: 55%;background: var(--speciBlue);height: 100%;overflow: hidden;align-items: center;}
  .loginCarry .left .logoBig{width: 115%;height: 100%;left: -10%;top: 0;opacity: 0.1;bottom: 0;margin: auto;}
  .loginCarry .left .logoBig img{object-fit: cover;height: 100%;}
  .loginCarry .left h1{font-size: 3.5vw;color: #fff;font-weight: 800;line-height: 3.5vw;margin-left: 10%;}
  .loginCarry .right{width: 45%;background: #fff;height: 100%;}
  .loginCarry .right .block{width: 50%;}
  .loginCarry .right .block .logo{width: 80%;}
  .loginCarry .right .block .textBox{width: 100%;font-size: 14px;padding: 12px 10px;padding-right:22px ;margin-top: 4px;border-radius: 8px;}
  .loginCarry .right .block label .icons{right: 8px;top: 15px;font-size: 18px;color:var(--speciBlue);}
  .loginCarry .right .block .forgotPass{margin-top: 4px;}
  .loginCarry .right .block .forgotPass a{font-size: 14px;color: #999;}
  .loginCarry .right .block .forgotPass a span{font-size: 18px;}
  .loginCarry .right .block .submitButton.login{background: var(--speciBlue);color: #fff;font-size: 18px;padding: 12px;border-radius: 40px;letter-spacing: 1px;cursor: pointer;margin-top: 20px;}
  .loginCarry .right .block .dontHaveAc{margin-top: 10px;color: #666;font-size: 15px;}
  .loginCarry .right .block .dontHaveAc b{color: var(--speciBlue);font-size: 16px;}
  .loginCarry .right .block .loginText{color: #999;}
  .mainHeader{background:rgba(255, 255, 255, 0.4);padding: 8px;align-items: center;backdrop-filter: blur(8px);position: relative;z-index: 2;}
  .mainHeader .mainnavigations br{display: none;}
  .mainnavigations a,  .mainnavigations span{color:#001952;margin: 0 8px;cursor: pointer;font-weight: 500;}
  .mainnavigations a.active,  .mainnavigations span.active{color: var(--speciBlue);font-weight: 600;}
  .mainHeader .logo{width: 60px;height: 60px;background: var(--speciBlue);padding: 10px 6px 6px 6px;border-radius: 10px;margin-left: 1%;cursor: pointer;}
  .mainHeader .mainnavigations{width: 60%;vertical-align: middle;padding-left: 4%;}
  .mainHeader .profileicons{width: 34%;text-align: right;vertical-align: middle;}
  .mainHeader .profileicons .profilePicture{width: 60px;height: 60px;border-radius: 50%;overflow: hidden;margin-left: 4px;}
  .mainHeader .headericons{font-size: 30px;}
  .mainHeader .settingsIcons, .mainHeader .notificationIcon, .mainHeader .profileBlock, .mainHeader .headericons{margin-left: 1.5%;margin-right: 1.5%;color: #4f689a;}
  .profileBlock a{color: #252525;}
  .notificationIcon .notificationBadge{width: 20px;height: 20px;font-size: 12px;color: #fff;background: rgba(255, 0, 0, 0.8);border-radius: 50%;padding-top: 2px;text-align: center;top: -5px;right: -3px;}
  .mainHeader .profileBlock{padding-left: 8px;}

  .notificationIcon .popupBlock{width: 400px;}

  .popupBlock{top:102%;right: -2%;background: rgba(255, 255, 255, 0.4);;box-shadow: 0px 1px 8px rgba(0,0,0,0.2);padding: 15px;border-radius: 20px;text-align: left;display: none;backdrop-filter: blur(3px);}
  .popupBlock .blockPop{max-height: 300px;overflow-y: auto;height: fit-content;margin-top: 10px;}
  .popupBlock a{border-bottom: 2px #f1f1f1 solid;padding: 15px 0;margin-top: 0px;transition: 0.4s;}
  .popupBlock a:hover{border-bottom: 2px var(--speciBlue) solid;}
  .popupBlock a:nth-child(1){padding-top: 0;}
  .popupBlock h4{font-size: 15px;color:#666;font-weight: 500;}
  .popupBlock p{line-height: 18px;font-size: 15px;color: #666;margin-top: 5px;}
  .popupBlock h3{color: var(--speciBlue);}
  .popupBlock .moreButton{border-bottom: 0px #f1f1f1 solid;margin-top: 10px;font-size: 14px;color: #999;}
  .popupBlock .moreButton span{font-size: 16px;}
  .popupBlock .closePopup{font-size: 20px;top: 15px;right: 15px;width: 25px;height: 25px;border-radius: 50%;background: var(--speciBlue);color: #fff;text-align: center;padding-top: 2px;}

  .loadingBlock{width: 100%;height: 100%;left: 0;top: 0;z-index: 50;background: rgba(255, 255, 255, 0.9);backdrop-filter: blur(2px);}
  .loadingBlock .loadingBlockSpan{width: 120px;height: 110px; padding: 15px;}
  .loadingBlock .loadingBlockSpan .image{left: 0;top: 0;border-radius: 25px;}
  .loadingBlock .loadingBlockSpan .image img{width: 80%;margin-top: 15px;display: block;margin-left: auto;margin-right: auto;}
  .loadingBlock .loadingBlockSpan:after{position: absolute;width: 110%;height: 110%;content: "";left: -5%;top:-5%;filter: blur(10px);z-index: 0;transform: rotate(0deg);animation: 10s infinite cubic-bezier(0,2.02,.5,-0.24) loadGradient;border-radius: 80%;}
  @keyframes loadGradient{
    0%{transform: rotate(0deg) scale(1.2);background: linear-gradient(to left, #DBF227, #4f689a, #F26457);filter: blur(10px);}
    50%{transform: rotate(180deg) scale(.6);filter: blur(10px);}
    100%{transform: rotate(360deg) scale(1.1);background: linear-gradient(to left, #DBF227,#4f689a, #F26457);filter: blur(25px);}
  }


  .dashboardBlock{width: 98%;margin-top: 0;}
  .dashboardBlock .widgetBlock.testCarry{background: var(--speciBlue);}
  .dashboardBlock .widgetBlock.testCarry .left{color: #fff;}
  .dashboardBlock .widgetBlock.testCarry .right a{color: #fff;}
  .dashboardBlock .widgetBlock.testCarry .right h4{color: #fff;}
  .dashboardBlock .widgetBlock.testCarry  .navigationsOfwidgets a{color: #fff;}
  .dashboardBlock .widgetBlock.testCarry .navigationsOfwidgets a.a{background: #fff;color: var(--speciBlue);border: 1px #fff solid;color: var(--speciBlue);}
  .dashboardBlock .widgetBlock.testCarry .navigationsOfwidgets a.b{border: #fff solid 1px;}
  .dashboardBlock .widgetBlock.testCarry span.mainTimeSpan{color: #fff;border-top:1px rgba(255,255,255,0.2) solid;border-bottom:1px rgba(255,255,255,0.2) solid;padding: 5px 0;margin-top: 8px;font-size: 20px;}
  .dashboardBlock .widgetBlock.testCarry span.mainTimeSpan span.a{width: 90px;}
  .dashboardBlock .widgetBlock.testCarry span.mainTimeSpan span.b{font-size: 14px;}
  .dashboardBlock .widgetBlock{border: 1px var(--speciBlue) solid;padding: 2%;padding-bottom: 75px; border-radius: 30px;width: 31%;min-width: 350px;margin: 2% 1% 0 1%;}
  .dashboardBlock .widgetBlock .left{font-size: 55px;color: var(--speciBlue);}
  .dashboardBlock .widgetBlock .right{padding: 0 10px;}
  .dashboardBlock .widgetBlock .right a{color: var(--speciBlue);font-size: 15px;margin-top: 10px;}
  .dashboardBlock .widgetBlock .right h4{font-size: 24px;color:var(--speciBlue);}
  .dashboardBlock .widgetBlock .navigationsOfwidgets{bottom: 15px;left: 0;}
  .dashboardBlock .widgetBlock .navigationsOfwidgets a{padding: 8px 15px;border-radius: 50px;font-size: 15px;border: 1px var(--speciBlue) solid;color: var(--speciBlue);font-weight: 600;}
  .dashboardBlock .widgetBlock .navigationsOfwidgets a span{font-size: 16px;padding: 0 3px;width: fit-content;}
  .dashboardBlock .widgetBlock .navigationsOfwidgets{margin-top: 20px;width: 100%;}
  .testCount{margin-top: 25px;}
  .dashboardBlock h2{font-size: 30px;color: #fff;font-weight: normal;}
  .dashboardBlock h3{font-size: 20px;color: #fff;margin-top: 10px;border-top: 1px rgba(255,255,255,0.2) solid;border-bottom: 1px rgba(255,255,255,0.2) solid;padding: 8px 0;}
  .dashboardBlock h3 span.b{font-size: 15px;font-weight: normal;margin-left: 5px;}
  .dateSpan{color: #fcd23e;margin-top: 10px;}

  .pageHeader .left{width: 20%;}
  .pageHeader .left h2{font-size: 28px;color: var(--speciBlue);padding-left: 3%;word-wrap: break-word;}
  .pageHeader .search{width: 40%;}
  .pageHeader .search .searchFld{width: 96%;border-radius: 50px;padding: 12px 45px 12px 15px;}
  .pageHeader .search button{top:23%;right: 5.8%;font-size: 8px;width: 35px;height: 35px;background: #e2edfb;cursor: pointer;border-radius: 50%;color: #4f689a;}
  .pageHeader .search button span{font-size: 22px;}
  .pageHeader .butons{width: 40%;}
  .pageHeader .butons .butBlock{width: auto;padding: 10px 14px 12px 16px;border-radius: 50px;margin: 6px 0 0 6px;color: var(--speciBlue);font-size: 14px;font-weight: 600;text-align: center;}
  .pageHeader .butons .butBlock a{color: var(--speciBlue);}
  .pageHeader .butons .butBlock a span{font-size: 14px;}
.pageHeader .butons .butBlock span{font-size: 13px;}
.pageHeader .butons .butBlock.back{padding: 10px 16px 12px 14px;}
.pageHeader .butons .butBlock.back span{margin-right: 5px;}
.pageHeader .butons .butBlock.add{background: var(--speciBlue);color: #fff;}
.pageHeader .butons .butBlock.add a{color: #fff;}
.pageHeader .butons .butBlock.add span{font-size: 18px;}

.pageContainer{width: 100%;background: rgba(255,255,255,0.5);border-radius: 10px; border-top-right-radius: 25px;border-top-left-radius: 25px;height: 75vh;min-height: 50vh;padding-bottom: 40px;margin-top: 15px;overflow-y: auto;position: relative;padding-top: 10px;}
.pageContainer .Heads, .pageContainer .contents{width: 98%;}
.pageContainer .contents.branch div{align-self: baseline;}
.pageContainer .contents span.Datas{margin-top: 5px;font-weight: 500;transition: 0.3s;cursor: pointer;}
.pageContainer .contents span.Datas:hover{border-left:2px solid #ddd;padding-left: 5px;}
.pageContainer .contents .aofBlock{font-size: 14px;color: #000;background: #d6fafc;padding:3px 10px 5px 10px;border-radius:10px;margin-top: 8px;}
.pageContainer .Heads div{text-transform: uppercase;font-size: 12px;letter-spacing: 1px;font-weight: 600;color: #666;}
.pageContainer .contents{background: #fff;border-radius: 8px;padding: 5px 8px 5px 8px;margin-top: 10px;transition: 0.6s;z-index: 0;position: relative;font-size: 15px;line-height: 24px;}
.pageContainer .contents:hover{box-shadow: 0px 0px 50px rgba(0,28,132,0.2);z-index: 1;}.pageContainer .contents div:last-child{justify-content: end;}
.pageContainer .contents div{color: #333;align-self: baseline;}
.pageContainer .contents a{color: #252525;}
.pageContainer .contents a:hover{text-decoration: underline;}
.pageContainer .Heads .one,.pageContainer .contents .one {width: 5%;}
.pageContainer .Heads .two,.pageContainer .contents .two {width: 20%;}
.pageContainer .Heads .three,.pageContainer .contents .three {width: 20%;}
.pageContainer .Heads .four,.pageContainer .contents .four {width: 20%;}
.pageContainer .Heads .five,.pageContainer .contents .five {width: 15%;}
.pageContainer .Heads .six,.pageContainer .contents .six {width: 20%;justify-content: end;}
.pageContainer .contents div:last-child span{width: 35px;height: 35px;text-align: center;background: #e2edfb;border-radius: 50%;margin: 0 5px;padding-top: 5px;transition: 0.4s;color: var(--speciBlue);}
.pageContainer .contents div:last-child span a{color: var(--speciBlue);display: block;width: 100%;height: 100%;}
.pageContainer .contents div:last-child span a:hover{text-decoration: none;}

.pageContainer .contents select{font-size: 15px;padding: 8px 30px 8px 10px;border-radius: 40px;background: #fff;border: 1px #f1f1f1 solid;}
.pageContainer .contents div:last-child span:hover{color: #fff;background: #23538B;}
.pageContainer .contents div:last-child span:hover a{color: #fff;}
.pageContainer .contents:hover div:last-child span.delete{color: #fff;background: #cc5252;}
.pageContainer .contents div span.spcl{font-size: 16px;color: #000;}
.pageContainer .contents div span.mrp{font-size: 15px;color: #666;margin-top: 5px;}
.pageContainer .contents.inactivated{background: #eff4fd!important;box-shadow: 0px 0px 0px #000;position: relative;overflow: hidden;border: 1px #fff solid;}
.pageContainer .contents.inactivated:after{position: absolute;content: "";width: 80%;height: 100%;left: 3px;top: 0;background: #eff4fd;backdrop-filter: blur(10px);opacity:0.7;}
.pageContainer .contents.inactivated.deleted{opacity: 0.5;}
.pageContainer .contents.inactivated:before{position: absolute;content: "";width: 2px;height: 100%;left: 0;top: 0;background: #de8410;}
.pageContainer .contents.inactivated.deleted:before{display: none;}

select{-webkit-appearance: none;appearance: none;}
.selectBoxCarry .arrowIcons{right:16px;top: 15px;}
.pageContainer .contents .statusBadge{background:none;width: 35px;height: 35px;border-radius: 50px;top: 0;left: 10px;padding-top: 8px;text-align: center;font-size: 14px;font-weight: 600;color: #666;}
.pageContainer .contents .label{font-size: 11px;color: #999;top: -5px;left: auto;text-transform: uppercase;font-weight: 500;}
.pageContainer .contents div.selectBoxCarry .label{top: 3px;background: #fff;padding: 0 4px;left: 15px;}
.pageContainer .contents.pending .statusBadge{background:#de8410;color: #fff;}
.pageContainer .contents.completed .statusBadge{background:#168628;color: #fff;}
.pageContainer .contents.inactivated .selectBoxCarry .label{background: #ddd;border-radius: 10px;}

.addLocationBlock{width: 50%;margin-top: 15px;}
.addLocationBlock h4{font-size: 25px;font-size: 100;color: #333;}
.addLocationBlock .block{background: #fff;padding: 20px;border-radius: 20px;margin-top: 15px;width: 80%;}
.addLocationBlock .block label{font-size: 14px;text-transform: uppercase;color: #333;margin-bottom: 8px;}
.addLocationBlock .block select{font-size: 16px;padding: 6px 0;}
.addLocationBlock .block .arrow{right: 15px;bottom:15px ;color: #999;}
.addLocationBlock .checkBt{margin-right: 4px;}
.textForm, .formSelect, .submitForm, .textFormTa{font-size: 16px;}
.textFormTa{height: 80px;resize: none;}
.submitForm{background: var(--speciBlue);color: #fff;text-transform: uppercase;padding: 15px;width: 80%;border-radius: 40px;font-size: 18px;margin-top: 15px;}
.viewLocationBlock{background: #fff;padding: 25px;margin-top: 40px;border-radius: 20px;width: 40%;margin-left: 5%;}
.viewLocationBlock h3{color: var(--speciBlue);margin-bottom: 12px;}
.viewLocationBlock .blocks{padding-bottom: 10px;border-bottom: 1px #ddd solid;color: #666;}
.viewLocationBlock .blocks b{color: #333;}
.viewLocationBlock .blocks .editLocView{right: 10px;width: 40px;height: 40px;border-radius: 50%;background: #f1f1f1;text-align: center;font-size: 20px;padding-top: 1.2%;color: #252525;transition: 0.5s;right: 0;}
.viewLocationBlock .blocks .editLocView:hover{background: var(--speciBlue);color: #fff;}
.viewLocationBlock .blocks .numberSpan{margin-right: 8px;font-size: 14px;color: #999;}

.errorBlock{width: 100%;font-size: 16px;color: #f00;background: #ffe4e5;padding:5px;transition: 1s ease;text-align: center;top: 0px;position: absolute;font-weight: 600;left: 0;}
.showNow{display: block;margin-top: 0px;}
.succssMsg{width: 100%;font-size: 15px;color: #038100;background: #ddffdc;padding:5px;transition: 1s ease;text-align: center;top: 0px;position: absolute;font-weight: 400;left: 0;}
.succssMsg span{font-size: 20px;}

.noninterNetMsg{width: 100%;height: 100vh;flex-direction: column;}
.noninterNetMsg img{min-width: 250px;width: 20vw;}
.noninterNetMsg .block{font-size: 20px;font-weight: 600;width: 100%;text-align: center;}
.noninterNetMsg .block span{color: var(--speciBlue);font-size: 22px;}
.noninterNetMsg .block p{font-weight: normal;}
.arrowSelect{bottom: 20px;right: 15px;}

.cancelEditBut{font-size: 16px;padding: 5px 8px;}
.cancelEditBut span{font-size: 20px;margin-right: 2px;}

.notFountMsg{color: #999;}
.notFountMsg h4{font-size: 25px;font-weight: normal;}
.backtoMainButton{background: #23538B;color: #fff;padding: 10px 24px;border-radius: 20px;margin-top: 25px;}
.TtlcountDiv{font-size: 14px;padding:15px 35px!important;bottom: -1px;left: 0;color: rgba(0,0,0,0.5);text-align: center;}
.backSerachBut{border: 1px #ccc solid;padding: 8px 15px;border-radius: 35px;}

.offRate{font-size: 14px;color: #666;border: 1px #f1f1f1 solid;margin-left: 5px;padding: 5px 10px;border-radius: 20px;}

.blockViewMore{padding: 25px;border-top: 1px #ddd solid;}

.popUpView{overflow: hidden;width: 0;height: 0;transition: 0.1s ease;left: 50%;top: 50%;transform: translate(-50%, -50%);}
.popUpView.show{background: rgba(255,2552,255,0.6);position: fixed;width: 100%;height: 100%;backdrop-filter: blur(10px);align-items: center;justify-content: center;transition: 0.5s ease;z-index: 15;}
.popUpView.show .contentDiv{width: 70%;min-height: 200px;background: #fff;box-shadow:0 0 80px rgba(0,28,132,0.15);border-radius: 10px;height: auto;max-height: 80vh;overflow-y: auto;justify-content: space-between;}
.popUpView.show .contentDiv .closePopup{right: 25px;top: 25px;font-size:30px;font-weight: bold;background: #cc5252;color: #fff;border-radius: 5px;cursor: pointer;}
.ClinetlogoImage{width: auto;max-width: 120px;}
.popUpView.show .contentDiv .block{width: 48%;}
.ClinetlogoListImage{width: 50px;margin-right: 5px;border-radius: 50%;overflow: hidden;height: 50px;border: 1px #f1f1f1 solid;}
.popUpView.show .contentDiv .block .label{font-size: 15px;color: #666;}
.popUpView.show .contentDiv .block .label h4{font-size: 16px;font-weight: 500;}
.editViewButn{font-size: 14px;color: #001952;background: #e4ecff;padding: 5px 15px;border-radius: 8px;margin-top: 20px;}
.editViewButn span{font-size: 16px;margin-right: 4px;}
.deletePop{margin: 18px 0 0 5px;width: 40px;height: 30px;background: #e10000;color: #fff;text-align: center;border-radius: 8px;padding-top: 2px;}
.nowImageShow{width: 60px;height: 60px;border:1px solid #ddd;right: 15px;top: 15px;border-radius: 8px;overflow: hidden;font-size: 12.5px;text-align: center;padding: 1px;background: #fff;}
.resetPasswordInfo{background: #ffe5e5;color: #521616;font-size: 13px;padding: 5px 10px;border-radius: 8px;margin-top: 8px;}
.resetPasswordInfo a{font-size: 13px;padding: 0 0 0 4px;color: #521616;margin-top: -3px;}

.conformationPopUp{width: 0%;height: 100%;overflow: hidden;transition: 0.1s ease;padding: 0;z-index: -5;opacity: 0;left: 50%;top: 50%;transform: translate(-50%, -50%);background: none;background: rgba(0,0,0,0.2);}
.conformationPopUp.show{width: 100%;z-index: 20;opacity: 1;}
.conformationPopUp .contBlock{background: #fff;padding: 50px;box-shadow: 0px 0px 60px rgba(19, 21, 19, 0.4);border-radius: 5px;}
.conformationPopUp .contBlock .delete{background: #e10000;color: #fff;padding: 10px 20px 12px 20px;border-radius: 25px;margin-top: 15px;font-size: 18px;}
.conformationPopUp .contBlock h2{font-weight: normal;font-size: 18px;color: #666;}
.conformationPopUp .contBlock h2 span{font-weight: 600;}
.conformationPopUp .contBlock .delete span{margin-right: 5px;transform: translateY(-1px);}
.conformationPopUp .contBlock .cancel{padding: 10px 20px 12px 20px;margin-left: 5px;border: 1px #ddd solid;border-radius: 25px;}
.pageContainer .contents div:last-child span.userCount{width: 20px;height: 20px;border-radius: 50%;background: #33a87a;color: #fff;font-size: 12px;font-family: Arial, Helvetica, sans-serif;top: -6px;right: -10px;line-height: 10px;}
.addLocationBlock .block span.showPasswordBut{width: 30px;height: 30px;color: #001952;border-radius: 50%;font-size: 28px;text-align: center;padding-top: 4px;cursor: pointer;right: 15px;top: 44%;opacity: 0.5;}
.addLocationBlock .block span.showPasswordBut:hover{opacity: 0.9;}
.settingsIcons .headericons{transition: 0.4s;}
.settingsIcons:hover .headericons{transform: rotate(50deg);}
.hoverAllBlock{width: 160px;top: 100%;right: 0;background: #fff;text-align: left;padding: 0px;border-radius: 8px;box-shadow: 2px 0px 20px rgba(0,28,132,0.2);opacity: 1;overflow: hidden;transition: 0.2s ease-out;height: auto;max-height: 0px;}
.hoverAllBlock a{color:#001952 ;}
.hoverAllBlock.show{opacity: 1;padding: 10px;max-height: 1500px;}
.hoverAllBlock span{width:100%;;border-bottom: 1px #ddffdc solid;padding: 5px 0;}
.linkOfPath{font-size: 12px;color: #333;text-decoration: underline;}

.pathViewSpan span{font-size: 13px;width: 90%;line-height: 20px;display: block;}
.pathViewSpan span span{width: auto;display: inline-block;}
.addLocationBlock.RoleAdd{width: 100%;}
.addLocationBlock.RoleAdd .CarryBlock{width: 48%;}
/* .addLocationBlock.RoleAdd .CarryBlock .block .blk{} */
.addLocationBlock.RoleAdd .CarryBlock .block .blk.uderNameDiv{font-weight: 600;width: 30%;}
.addLocationBlock.RoleAdd .CarryBlock .block .blk.RoleSelector{text-align: right;width:69%;}
.addLocationBlock.RoleAdd .CarryBlock .block .blk.RoleSelector span{padding: 0 5px;}
.addLocationBlock.RoleAdd .CarryBlock .block .blk.RoleSelector span input{margin-right: 4px;}
.addLocationBlock.RoleAdd .CarryBlock .block{width: 100%;}
.subBlock{border: 1px #ccc solid;border-radius: 10px;margin-top: 15px;}
.subBlock .blkSub{width: 48%;}
.subBlock .blkSub.right{text-align: right;}
.subBlock .blkSub.right span{padding: 0 5px;}
.subBlock .blkSub.right input{margin-right: 4px;}
.subBlock .blkSub.left{font-weight: 500;}
.checkRole{margin-right: 5px;}

.profileViewBlock{justify-content: space-between;margin-top: 0;}
.profileViewBlock h5{font-size: 16px;}
.profileViewBlock .BlockDbl{width: 49%;}
.profileViewBlock .profilePic{width: 80px;height: 80px;border-radius: 50%;overflow: hidden;margin-right: 10px;}
.profileViewBlock .block label{margin-bottom: 3px;}

.driverStatus{background: #fff1d9;color: #532906;padding: 2px 10px;border-radius:50px;font-size: 13.5px;margin-top: 8px;font-weight: 600;}

.viewTestButton{color: #001952;font-size: 14px;padding: 0px 0;margin-top:1px;text-decoration: underline;}
.testViewBlock, .statusViewBlock{width: 100%;height: 100%;background: rgba(255,2552,255,0.8);top: 0;left: 0;z-index: 10;backdrop-filter: blur(5px);display: none;}
.testViewBlock.show, .statusViewBlock.show{display: flex;}
.testViewBlock .closeTestView, .statusViewBlock .closeTestView{font-size: 28px;font-weight: 600;right: 10px;top: 10px;}
.comnBlockPop .content{background: #fff;padding: 20px; box-shadow: 0px 0px 14px rgba(0,0,0,0.1);border-radius: 5px;width: 60%;max-width: 750px;max-height: 80vh;overflow-y: auto;}
.comnBlockPop .content .block{width: 48%;}
.comnBlockPop .content .block h5{font-size:15px}
.comnBlockPop .content .block p{font-size: 14px;margin-top: 5px;}
.testViewBlock.show .changeStatusSelectBox{margin-top: 0;padding: 0 15px 0 0;width: auto!important;background: #e4f4ff;border-radius: 8px;}
.testViewBlock.show .changeStatusSelectBox select{border: 0;padding:4px 8px;font-size: 14px;}
.testViewBlock.show .changeStatusSelectBox span {right: 6px;font-size: 18px;top: 5px;}
.comnBlockPop .content .createdDate{font-size: 12px;color: #666;border-top: 1px #f1f1f1 solid;padding-top: 8px;}
.comnBlockPop .content .block .blockOftest{margin-top: 6px;}
.comnBlockPop .content .block .blockOftest .CountTesti{left: -24px;font-size: 14px;font-weight: 600;top: 9px;color: #666;width: auto;border-right: 1px #ddd solid;padding-right: 4px;}
.comnBlockPop .content .block .blockOftest span{width: 48%;}
.comnBlockPop .content .block .blockOftest h5{font-size: 15px;}
.arrowCatTest{font-size: 15px;color: #999;margin: 0 1px;}
.categoryViewBlock{font-size: 15px;}

.addMyTestBlock .addLocationBlock{width: 48%;max-height: 50vh;overflow-y: auto;}
.testSelectBlock{background: #fff;border-radius: 8px;margin-top: 42px;}
.testSelectBlock ul li{font-size: 14px;margin-bottom: 6px;cursor: pointer;padding: 2px 0;}
.testSelectBlock ul li:hover{font-weight: 600;color: #000;}
.testSelectBlock .blockView{width: 33%;padding: 16px!important;}
.testSelectBlock .three ul li{margin-bottom: 0px;border-bottom: 1px #f1f1f1 solid;}
.testSelectBlock .blockView:nth-child(2){border: 1px #ddd solid;border-top: 0;border-bottom: 0;}
.testSelectBlock ul li input{margin-right: 4px;transform: translateY(-2px);}
.testViewStatus{font-size: 13px;background: #ffefbf;color:#5a1e09;width: fit-content!important;font-weight: 600;padding: 2px 10px;text-align: center;border-radius:10px;}
.testSelectBlock ul li .activeSpanTestAdd{width: 10px;height: 10px;background: #1581cd;display: inline-block;vertical-align: middle;border-radius: 20px;margin-right: 5px;}


/* .testSelectBlock .subUi, .testSelectBlock .testUi{margin-left: 25px;margin-top: 10px;}
.testSelectBlock ul .mainUlLi{font-weight: 600;}
.testSelectBlock ul .mainUlLi{font-weight: 600;} */

.testSelectBlock .blockSeen{padding: 0px;}
.categorySowAddTest{font-size: 11px;color: #999;transform: translateY(-3px);}
.clientLoginBut{font-size: 14px;color: #666;margin-top: 10px;text-decoration: underline;}
.changeStatusSelectBox{width: 100%!important;overflow: hidden;margin-top: 14px;}
.changeStatusSelectBox select{border-bottom: 1px #f1f1f1 solid;padding: 5px 0;font-size: 15px;}
.changeStatusSelectBox span{width: auto!important;right: 10px;}
.changeStatusBut{background: var(--speciBlue);color: #fff;text-transform: uppercase;padding: 10px 18px;border-radius: 5px;cursor: pointer;margin-left: 10px;letter-spacing: 1px;}
/* .succssMsg{display: none;} */

@media (max-width:1600px) {

}
@media (max-width:1200px) {

}
@media (max-width:990px) {
 
}

@media (max-width:768px) {   

}
@media (max-width:585px) { 
  
}
@media (max-width:480px) {  

}

.material-symbols-outlined {font-variation-settings:'FILL' 0,'wght' 400,'GRAD' 0,'opsz' 48}
  

.scrollBarCustom{padding-right: 5px!important;overflow: hidden;overflow-y: auto;}
.scrollBarCustom::-webkit-scrollbar {width: 2px;}
.scrollBarCustom::-webkit-scrollbar-track {background: #ddd;}
.scrollBarCustom::-webkit-scrollbar-thumb { background: #999;height: 200px;}
.scrollBarCustom::-webkit-scrollbar-thumb:hover {background: #666;}
.scrollBarCustom{scrollbar-width: thin;scrollbar-color: none;scrollbar-track-color: #ddd;}
  
body{animation: 0.6s ease-in-out pageloadanimation forwards;min-height: 100vh;width: 100%;background: linear-gradient(45deg, #E0EAFC, #CFDEF3);}
@keyframes pageloadanimation{
  from{opacity: 0;transform: translateX(80px);}
  to{opacity: 1;transform: translateX(0px);}
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 10px #fff inset;
}